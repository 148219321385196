import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import Toaster from "../components/Toaster"
import SEO from "../components/seo"

const InquirePage = ({data}) => {
  const query = data.allContentfulInquire.edges[0].node;
  const [emailCopied, setEmailCopied] = useState(false);
  const [windowHeight, setWindowHeight] = useState('100vh')

  const handleSetWindowHeight = () => {
    setWindowHeight(window.innerHeight)
  }

  useEffect(() => {
    handleSetWindowHeight()
    window.addEventListener('resize', handleSetWindowHeight)

    return () => {
      window.removeEventListener("resize", handleSetWindowHeight);
    }
  })

  const copyEmail = () => {
    const el = document.createElement('textarea');
    el.value = query.email;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setEmailCopied(true)
    setTimeout(() => {
      setEmailCopied(false)
    }, 3000);
  };

  return (
    <Layout className='landingLayout' windowHeight={windowHeight} style={{ 'overflow': 'hidden', 'position': 'relative' }}>
      <SEO title="Inquire" />
      <div id='mainContent'>
        <Section className='landingSection'>
          <h2>{query.title}</h2>
          <article>
            <p>Isaac Daniels <span>{query.location}</span></p>
            <p><button onClick={() => copyEmail()}>Copy email to clipboard</button></p>
          </article>
        </Section>

      </div>
      <div>
        <Section className='mediaSection'>
          {query.media.file.contentType.includes('video')
            ? <div className='mediaContainer'>
                <video
                  // autoPlay={query.media.description.includes('controls') ? false : true}
                  controls={true}
                  // controls={query.media.description.includes('controls') ? false : true}
                  muted={query.media.description.includes('controls') ? true : false}
                  loop={query.media.description.includes('controls') ? false : true}
                  width='925'
                  playsInline={true}
                  preload='auto'
                >
                  <source src={query.media.file.url} type={query.media.file.contentType} />
                </video>
              </div>
            : null
          }
        </Section>
      </div>

      <Toaster emailCopied={emailCopied} setEmailCopied={setEmailCopied} />

    </Layout>
  )
}

export const query = graphql`
  query InquireQuery {
    allContentfulInquire {
      edges {
        node {
          id
          title
          location
          email
          media {
            id
            description
            file {
              url
              fileName
              contentType
            }
            
          }
        }
      }
    }
  }
`

export default InquirePage
