import React from 'react'
import './Toaster.scss'

const Toaster = ({emailCopied, setEmailCopied}) => (
  <div 
    className='toaster'
    style={emailCopied ? {'transform': 'translateY(0)' } : null}
  >
    <span>Email copied.</span>
    <button
      className='toasterClose'
      onClick={() => setEmailCopied(false)}
    >
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1L1 9M9 9L1 1" stroke="black" />
      </svg>
      <span>Close</span>
    </button>
  </div>
)

export default Toaster